import { Box, Button, ChakraProvider, Text, theme, Image, Divider, Stack } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import QR from'../images/qr.png'


function Donate() {
  const navigate = useNavigate()
  function handleClick150() {
    window.open('https://mpago.la/1gRrgm9', '_blank', 'noreferrer');
  }
  function handleClick300() {
    window.open('https://mpago.la/2x5cnSr', '_blank', 'noreferrer');
  }
  function handleClick600() {
    window.open('https://mpago.la/1RYjYwf', '_blank', 'noreferrer');
  }
  return (
    <ChakraProvider theme={theme}>
      <Box display={'flex'} flexDir='column' justifyContent={'center'} alignItems='center' minH={'100vh'} maxW='600px' m='auto'>
        <Text>Gracias por colaborar con el desarrollo de</Text>
        <Text fontSize={'2xl'} fontWeight={'bold'}>Competencia E-commerce</Text>
        <Divider my='1rem'></Divider>
        <Text textAlign={'center'}>Escanea el código para donar la cantidad que quieras con tu billetera electrónica favorita o clickea uno de los botones para donar un monto fijo mediante la web de MercadoPago.</Text>
        <Box boxSize='150' display='flex' flexDir='column' m='2rem'>
          <Image src={QR} alt='Código QR'/>
        </Box>
        <Stack spacing='0.5rem'>
          <Button colorScheme={'green'} onClick={handleClick150}>$150</Button>
          <Button colorScheme={'green'} onClick={handleClick300}>$300</Button>
          <Button colorScheme={'green'} onClick={handleClick600}>$600</Button>
          <Button colorScheme={'blue'} onClick={() => navigate("/")}>Volver al inicio</Button>
        </Stack>
      </Box>
    </ChakraProvider>)
  }

export default Donate
