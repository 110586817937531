import { Box, Button, ChakraProvider, FormControl, FormLabel, Input, theme, useToast } from "@chakra-ui/react"
import { collection, onSnapshot, orderBy, query } from "firebase/firestore"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getAllMyArticles, getMyName } from "../api/meliapi"
import { db } from "../utils/firebase"
import { setMySellerIDOnDB } from "../utils/utils"

function SetSeller() {
    const toast = useToast()

    const [inputValue, setInputValue] = useState<string>('')
    const [mySellerID, setMySellerID] = useState<string>(localStorage.getItem("sellerID") || "")
    const [collectionName] = useState<string>(localStorage.getItem("uid") || "")
    const [localArray, setLocalArray] = useState<any[]>([])
    const [myName, setMyName] = useState<string>('')
    const navigate = useNavigate()


    useEffect(() => {
        if (collectionName !== "") {
          const collectionRef = collection(db, collectionName)
          const q = query(collectionRef, orderBy("displayName", "asc"))
          const unsub = onSnapshot(q, (snapshot) => setLocalArray(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))))
          return unsub
        }
      },[collectionName])
    
      useEffect(() => {
        const settingName = async () => {
          setMyName(await getMyName(mySellerID))
        }
        if (mySellerID.length > 0) {
          settingName()
        }
      }, [mySellerID])

    function setSeller(){
        getAllMyArticles('', 1, inputValue).catch()
          .then(json => {
              setMySellerID(inputValue)
              setMySellerIDOnDB(collectionName, inputValue)
          }).then(()=>navigate('/'))
          .catch(() => {
            toast({
            description: "Seller ID incorrecto o sin publicaciones.",
            status: 'error',
            duration: 2000,
            isClosable: true,
          })})
      }
    

return (
    <ChakraProvider theme={theme}>
      <Box width={'100%'} height={'100vh'} display='flex'>
        <FormControl maxWidth={'350px'} margin='auto'>
          <FormLabel fontWeight={'hairline'}>Si eres vendedor ingresa tu seller ID para continuar:</FormLabel>
          <Input isRequired placeholder='Seller ID' onChange={(e) => setInputValue(e.target.value)} />
          <Box marginY={'1rem'} display='flex'>
            <Button marginRight={'0.5rem'} flex={1} onClick={setSeller} colorScheme={'green'}>Aceptar</Button>
            <Button marginLeft={'0.5rem'} flex={1} onClick={()=>navigate('/')} colorScheme={'blue'}>No soy vendedor</Button>
          </Box>
        </FormControl>
      </Box>
        </ChakraProvider>)
    
}

export default SetSeller