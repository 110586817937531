import { Box, Button, ChakraProvider, Text, theme, CircularProgress } from "@chakra-ui/react"
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import ThemeButton from "../components/ThemeButton"
import { app } from "../utils/firebase"
import { getMySellerID } from "../utils/utils"

export interface ILoginPageProps {}

const LoginPage: React.FunctionComponent<ILoginPageProps> = (props) => {
  const auth = getAuth(app)
  const navigate = useNavigate()
  const [authing, setAuthing] = useState<boolean>(false)

  const signInWithGoogle = async () => {
    setAuthing(true)
    signInWithPopup(auth, new GoogleAuthProvider())
      .then(async (response) => {
        localStorage.setItem("uid", response.user.uid)
        localStorage.setItem("profileUrl", response.user.photoURL || "")
        const sellerID = getMySellerID(response.user.uid)
        if (await sellerID !== undefined) {
          localStorage.setItem("sellerID", await sellerID)
        }
        navigate("/")
      })
      .catch((error) => {
        console.log(error)
        setAuthing(false)
      })
  }

  if (authing) {
    return (
      <ChakraProvider theme={theme}>
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress isIndeterminate color='green.300' sx={{ marginBottom: "30px" }} />
          <Text>Conectando...</Text>
        </Box>
        </Box>
        </ChakraProvider>
    )
  } else {
    return (
      <ChakraProvider theme={theme}>
        <Box
          width={"100%"}
          height={"100vh"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <Box p={"50px"}>
            <ThemeButton/>
          </Box>
          
          <Text>Oh, parece que no estás conectado...</Text>
          <Button
            sx={{ width: "150px", margin: "50px" }}
            onClick={() => signInWithGoogle()}
            colorScheme={'green'}
          >
            Iniciar sesión
          </Button>
        </Box>
        
      </ChakraProvider>
    )
  }
}

export default LoginPage

