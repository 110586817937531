import { Card, CardBody, CardHeader } from "@chakra-ui/card"
import { Box, Button, ChakraProvider, Heading, Stack, StackDivider, Text, theme } from "@chakra-ui/react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

function Plans() {
  const navigate = useNavigate()
  const [direction, setDirection] = useState<'row'|'column'>('row')
  
  return (
    <ChakraProvider theme={theme}>
      <Box display='flex' flexDir='column' justifyContent={'center'} m='auto' alignItems={'center'} minH='100vh'>
        <Box display={'flex'} flexDir={direction} justifyContent={'center'} maxW='90%' mx='auto' >
          <Card backgroundColor={'blue.700'} maxW='md' m='1rem' px='2rem' borderRadius='1rem'>
            <CardHeader>
              <Heading size='lg' textAlign={'center'} m='1rem'>Plan Cero</Heading>
            </CardHeader>

            <CardBody flex='1'>
              <Stack divider={<StackDivider />} spacing='4' >
                <Box>
                  <Text pt='2' fontSize='sm'>
                    Duración ilimitada de las funciones gratis.
                  </Text>
                </Box>
                <Box>
                  <Text pt='2' fontSize='sm'>
                    Crea tantas comparaciones como necesites para hacer un seguimiento de precios.
                  </Text>
                </Box>
                <Box>
                  <Text pt='2' fontSize='sm'>
                    Agrega hasta 20 competidores por comparación.
                  </Text>
                </Box>
              </Stack>
            </CardBody>
            <CardHeader>
              <Heading size='md' textAlign={'center'} m='2rem'>Gratis</Heading>
            </CardHeader>
          </Card>
          <Card backgroundColor={'blue.700'} maxW='md' m='1rem' px='2rem' borderRadius='1rem'>
            <CardHeader>
              <Heading size='lg' textAlign={'center'} m='1rem'>Plan Premium</Heading>
            </CardHeader>
            <CardBody flex='1'>
              <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                    <Text pt='2' fontSize='sm'>
                      Renueva la suscripción al plan mensualmente.
                    </Text>
                </Box>
                <Box>
                  <Text pt='2' fontSize='sm'>
                    Agrega tantos competidores como creas necesario.
                  </Text>
                </Box>
                <Box>
                  <Text pt='2' fontSize='sm'>
                    Exporta informes con la información recopilada.
                  </Text>
                </Box>
                <Box>
                  <Text pt='2' fontSize='sm'>
                    Recibe notificaciones por correo electrónico cuando una comparación cambie de estado.
                  </Text>
                </Box>
              </Stack>
            </CardBody>
            <CardHeader display={'flex'} alignItems='center' flexDir={'column'} justifyContent='center' >
              <Button disabled={true} colorScheme={'green'} mt='2rem'>Comprar</Button>
              <Heading size='xs' textAlign={'center'} mb='2rem' mt='0.5rem' fontWeight={'light'}>Próximamente</Heading>
            </CardHeader>

          </Card>
        </Box>
        <Button my='2rem' colorScheme={'blue'} onClick={()=>navigate("/")}>Volver al inicio</Button>
      </Box>
    </ChakraProvider>)
  
  }

export default Plans
