import { Button } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

const SellerButton = () => {
  const navigate = useNavigate()
  
    return (
      <Button margin='0.5rem' colorScheme={'green'} onClick={()=>navigate("/vendedor")} fontSize={'smaller'}>¿Eres venededor?</Button>
    )
  }

  export default SellerButton