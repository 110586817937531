
import { Card, CardBody } from "@chakra-ui/card"
import { DeleteIcon } from "@chakra-ui/icons"
import { Box, IconButton, Link, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Stack, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { queryItem } from "../utils/Interfaces"

interface Props {
    item: queryItem
    quantity: number
    removeItem(id: string): void
    changeStock(newQuantity: number, item: queryItem): void
}

const CompetitorRow = ({ item, quantity, removeItem, changeStock }: Props) => {
    const [stockQuantity, setStockQuantity] = useState<number>(0)

    const handleStock = (value: any) => {
        setStockQuantity(value as number);
      };
  

    useEffect(() => {
        changeStock(stockQuantity, item)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [stockQuantity]);

    return (
        <Card
            maxWidth={'900px'}
            boxShadow={'3px 2px 2px 1px rgba(0, 0, 0, 0.2)'}
            width={'100%'}
            margin={'3px'}
            padding={'5px'}
            rounded={'5px'}
            justifyContent={'space-between'}
            borderLeftStyle={'outset'}
            borderLeftWidth={'2px'}
            borderColor={quantity === 0 ? 'red' : 'green'}>
            <CardBody width={'100%'} display='flex' dir='row' alignItems={'center'} justifyContent={'space-between'}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Link sx={{ p: 0, m: 0 }} href={item.permalink} isExternal>
                    <Text noOfLines={[1, 2]} mr='30px' sx={{ flex: 0.9 }} align="left" fontSize={'smaller'} fontWeight={'semibold'}>{item.title}</Text>
                </Link>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                <Box flexDir={'column'} display='flex' justifyContent={'flex-end'}>
                <NumberInput size='xs' sx={{ maxWidth: '100px' }} defaultValue={quantity} min={0} onChange={handleStock}>
                <NumberInputField />
                <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
                </Box>
                <Stack width={'50px'} marginLeft={'15px'} direction={'row'} alignItems={'center'}>     
                    <Box>
                        <IconButton onClick={()=>removeItem(item.id)} variant="ghost" color="primary" size={'small'} aria-label="Información" icon={<DeleteIcon />}/>
                    </Box>
                </Stack>
            </Box>
        </CardBody>
    </Card>)
}

export default CompetitorRow