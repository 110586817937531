import { db } from "./firebase"
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore"
import { Competitor, storeStock } from "./Interfaces"

export const AddComparisonToDB = async (
  displayName: string,
  referencePrice: number,
  comparisonItemsArray: Competitor[],
  collectionName: string,
  comparisonTag: string,
) => {
  const collectionRef = collection(db, collectionName)
  if (comparisonTag.length <= 0) {
    const payload = {
      displayName: displayName,
      referencePrice: referencePrice,
      comparisonItemsArray: comparisonItemsArray
    }
    await addDoc(collectionRef, payload)
  }
  else {
    const payload = {
      displayName: displayName,
      referencePrice: referencePrice,
      comparisonItemsArray: comparisonItemsArray,
      comparisonTag: comparisonTag
    }
    await addDoc(collectionRef, payload)
  }
}


export const deleteComparisonOfDB = async (id: string, collectionName: string) => {
  const docRef = doc(db, collectionName, id)
  await deleteDoc(docRef)
}


export const updateComparisonDB = async(
  displayName: string,
  referencePrice: number,
  comparisonItemsArray: Competitor[],
  comparisonTag:string|undefined,
  collectionName: string,
  id: string,
  ) => {
  const docRef = doc(db, collectionName, id)
  const payload = { displayName, referencePrice, comparisonItemsArray, comparisonTag }
  setDoc(docRef, payload)
}

export const queryTaskName = async (name: string, collectionName: string) => {
  const collectionRef = collection(db, collectionName)
  const q = query(collectionRef, where("name", "==", name))
  const snapshot = await getDocs(q)
  return snapshot.docs.length
}

export const getMySellerID = async (collectionName:string) => {
  const docRef = doc(db, collectionName, "sellerData");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().sellerId
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
}

export const setMySellerIDOnDB = async (collectionName: string, value: string) => {
  const docRef = doc(db, collectionName, 'sellerData')
  const payload = { sellerId: value }
  setDoc(docRef, payload)
}

export const setMyStockOnDB = async (collectionName: string, value: storeStock[]) => {
  const docRef = doc(db, collectionName, 'myStock')
  const payload = { value }
  setDoc(docRef, payload)
}

export const getMyStock = async (collectionName:string) => {
  const docRef = doc(db, collectionName, "myStock");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().value
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
}