import { Button } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

const CoexistenceButton = () => {
  const navigate = useNavigate()
  
    return (
      <Button disabled={true} margin='0.5rem' colorScheme={'green'} onClick={()=>navigate("/convivencia")} fontSize={'smaller'}>Convivencia</Button>
    )
  }

  export default CoexistenceButton