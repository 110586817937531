import { Competitor, CompetitorBody, CompetitorItem, queryItem, Response, Seller } from '../utils/Interfaces'
import { itemsPerPage } from '../utils/config'

export const getArticles = async (criteria: string, page: number) => {
  const urlToFetch = `https://api.mercadolibre.com/sites/MLA/search?q=${criteria}&offset=${(page - 1) * itemsPerPage}&limit=${itemsPerPage}`
  const data = await fetch(urlToFetch)
  
  const json: Response = await data.json()

  if (json.results && json.results.length>0) {
    return json as Response
  } else {
    throw new Error('No se encuentran resultados con ese criterio de búsqueda.')
  }
}

export const getMyArticle = async (comparisonString: string) => {
  const data = await fetch(
    `https://api.mercadolibre.com/items/${comparisonString}`
  )
  const json: queryItem = await data.json()
  if (json.id) {
    return json as queryItem
  } else {
    throw new Error('error')
  }
}

export const getAllMyArticles = async (criteria: string, page: number, sellerID: string) => {
  const urlToFetch = `https://api.mercadolibre.com/sites/MLA/search?seller_id=${sellerID}&q=${criteria}&offset=${(page - 1) * itemsPerPage}&limit=${itemsPerPage}`
  const data = await fetch(urlToFetch)
  
  const json: Response = await data.json()

  if (json.results && json.results.length>0) {
    return json as Response
  } else {
    throw new Error('No se encuentran resultados con ese criterio de búsqueda.')
  }
}

export const getAllMyCoexistenceArticles = async (page: number, sellerID: string) => {
  const urlToFetch = `https://api.mercadolibre.com/sites/MLA/search?seller_id=${sellerID}&logistic_type=fulfillment&offset=${(page - 1) * itemsPerPage}&limit=${itemsPerPage}`
  const data = await fetch(urlToFetch)
  
  const json: Response = await data.json()

  if (json.results && json.results.length>0) {
    return json as Response
  } else {
    throw new Error('No se encuentran resultados con ese criterio de búsqueda.')
  }
}

export const getComparison = async (comparisonString: string) => {
  const data = await fetch(
    `https://api.mercadolibre.com/items?ids=${comparisonString}`
  )
  const json: Array<CompetitorItem> = await data.json()
  if (json.length > 0 ) {
    return json as Array<CompetitorItem>
  } else {
    throw new Error('error')
  }
}

export const getMyName = async (mySellerID: string) => {
  const data = await fetch(`https://api.mercadolibre.com/users/${mySellerID}`)
  const json = await data.json()
  if (json.nickname) {
    return json.nickname as string
  } else {
    throw new Error ('No se encontró el nickname propio.')
  }
}

export const multiGetItems = async (comparisonArray: Competitor[]) => {
  const multiGetArray: string[] = comparisonArray.map(item => item.id)
  console.log(multiGetArray)
  let res: CompetitorBody[] = []
  if (multiGetArray.length > 0) {
    if (multiGetArray.length > 20) {
      let offset = 0
      console.log(`Este es el numero de consultas a realizar ${Math.ceil(comparisonArray.length / 20)}`)
      while (offset < Math.ceil(multiGetArray.length / 20)) {
        const getArray: string[] = comparisonArray.slice(offset * 20, offset * 20 + 20).map(item => item.id)
        console.log(getArray)
        if (multiGetArray.length > 0) {
          const data = await fetch(`https://api.mercadolibre.com/items?ids=${getArray.join()}`)
          const json: Array<CompetitorItem> = await data.json()
          if (json.length > 0) {
            res = res.concat(json.map(item => item.body))
            offset++
          } else {
            throw new Error('No se pudo obtener el listado de competidores')
          }
        } else {
          throw new Error('El listado de competidores está vacío')
          
        }
      }
      return res as CompetitorBody[]
    } else {
      const data = await fetch(`https://api.mercadolibre.com/items?ids=${multiGetArray.join()}`)
      const json: Array<CompetitorItem> = await data.json()
      if (json.length > 0) {
        const res: CompetitorBody[] = json.map(item => item.body)
        return res as CompetitorBody[]
      } else {
        throw new Error('No se pudo obtener el listado de competidores')
      }
    }
  } else {
    throw new Error ('El listado de competidores está vacío')
  }}

export const getSellerNick = async (sellerID: string) => {
  const data = await fetch(`https://api.mercadolibre.com/users?ids=${sellerID}`)
  const json: Array<{ code: number, body: any }> = await data.json()
  if (json.length>0) {
    return json[0].body as Seller
  } else {
    throw new Error ('No se pudo obtener el nick')
  }
}
  

