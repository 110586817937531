import { CheckIcon } from "@chakra-ui/icons"
import { Box, Checkbox, Icon, Image, Link, Stack, Text } from "@chakra-ui/react"
import { queryItem } from "../utils/Interfaces"
import FullfilmentIcon from "./FulFillmentIcon"


interface Props {
    item: queryItem
    checked: boolean
    addItem(item:queryItem): void
    removeItem(item: queryItem): void
    checkeable: boolean
  }
  
const ItemCard = ({ item, checked, addItem, removeItem, checkeable }: Props) => {
    
    
    function sendAction() {
        //setReload(true)
        if (checked) {
            removeItem(item)
            console.log('intenta remover')
        } else {
            addItem(item)
            console.log('intenta agregar')

        }
    }
    return (<Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginX:'auto',
            width: '90%',
            maxWidth: '90%',
            height: 'auto',
            borderLeftColor: 'gray',
            borderLeftStyle: 'outset',
            borderLeftWidth: '10px',
            boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.24)',
            margin: '0.5rem',
            
            padding: '15px',
        }}
    >
            <Image
                sx={{
                    height: 100,
                    width: 100,
                    backgroundSize: '100px'
                }}
                src={item.thumbnail}
                alt={item.thumbnail}
            />

        
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginX: '1rem',
            flex: 1 
        }}>
            <Link sx={{ p: 0, m: 0 }} href={item.permalink} isExternal>
                <Text align="right">{item.title}</Text>
            </Link>
            <Box display={'flex'} flexDir='row' justifyContent={'end'} alignItems='center'>
            {item.shipping.logistic_type === 'fulfillment' && <FullfilmentIcon/>}
            {String(item.listing_type_id) === 'gold_pro' && <Text align="right" fontSize={'xs'} textColor='#00a650'>Ofrece cuotas s/interés</Text>}
            <Text pl='1rem' align="right" fontWeight={'bolder'}>${item.price}</Text>
            </Box>
            <Link href={item?.seller.permalink} isExternal>
            {String(item.seller.id) !== localStorage.getItem('sellerID') ?
                <Text align="right">{item.seller.permalink.substring(item.seller.permalink.indexOf('ar/') + 3, item.seller.permalink.length)}</Text>
                :
                <Text align="right">Publicación propia <CheckIcon /></Text>
            }
            </Link>                       
        </Box>
        <Stack>
        <Checkbox
            inputProps={{ "aria-label": "controlled" }}
            defaultChecked={checked}
            onChange={sendAction}   
            readOnly={checkeable}
            onClick={()=>console.log("hola")}    
        />
        </Stack>
    </Box>)
}

export default ItemCard