
import { DeleteIcon, WarningTwoIcon } from "@chakra-ui/icons"
import { Box, IconButton, Link, Stack, Text, Tooltip, useMediaQuery } from "@chakra-ui/react"
import { Card, CardBody } from "@chakra-ui/card"
import { useEffect, useState } from "react"
import { getSellerNick } from "../api/meliapi"
import { CompetitorBody, Seller } from "../utils/Interfaces"
import { BiBox, BiCart } from "react-icons/bi"
import FullfilmentIcon from "./FulFillmentIcon"

interface Props {
    item: CompetitorBody
    referencePrice: number
    myName: string
    removeItem(id: string): void
}

const CompetitorRow = ({ item, referencePrice, myName, removeItem }: Props) => {
    const [seller, setSeller] = useState<Seller | null>(null)
    const [salesMin, setSalesMin] = useState<number>(0)
    const [salesMax, setSalesMax] = useState<number>(0)
    const [stockMin, setStockMin] = useState<number>(0)
    const [stockMax, setStockMax] = useState<number>(0)

    useEffect(() => {
        getSeller()
        setStock()
        setSales()
    }, [item])
    
    const getSeller = async () => {
        if (item) {
            setSeller(await (await getSellerNick(String(item.seller_id))))
        }
    }

    const setStock = () => {
        if (item) {
            switch (item.available_quantity) {
                case 0:
                    setStockMin(0)
                    setStockMax(0)
                    break
                case 1:
                    setStockMin(1)
                    setStockMax(50)
                    break
                case 50:
                    setStockMin(51)
                    setStockMax(100)
                    break
                case 100:
                    setStockMin(101)
                    setStockMax(150)
                    break
                case 150:
                    setStockMin(151)
                    setStockMax(200)
                    break
                case 200:
                    setStockMin(201)
                    setStockMax(250)
                    break
                case 250:
                    setStockMin(251)
                    setStockMax(500)
                    break
                case 500:
                    setStockMin(501)
                    setStockMax(5000)
                    break
                case 5000:
                    setStockMin(5001)
                    setStockMax(50000)
                    break
                case 50000:
                    setStockMin(50001)
                    setStockMax(99999)
                    break
            }
        }
    }

    const setSales = () => {
        if (item) {
            switch (item.sold_quantity) {
                case 0:
                    setSalesMin(0)
                    setSalesMax(0)
                    break
                case 1:
                    setSalesMin(1)
                    setSalesMax(1)
                    break
                case 2:
                    setSalesMin(2)
                    setSalesMax(2)
                    break
                case 3:
                    setSalesMin(3)
                    setSalesMax(3)
                    break
                case 4:
                    setSalesMin(4)
                    setSalesMax(4)
                    break
                case 5:
                    setSalesMin(5)
                    setSalesMax(5)
                    break
                case 6:
                    setSalesMin(6)
                    setSalesMax(25)
                    break
                case 25:
                    setSalesMin(26)
                    setSalesMax(50)
                    break
                case 50:
                    setSalesMin(51)
                    setSalesMax(100)
                    break
                case 100:
                    setSalesMin(101)
                    setSalesMax(150)
                    break
                case 150:
                    setSalesMin(151)
                    setSalesMax(200)
                    break
                case 200:
                    setSalesMin(201)
                    setSalesMax(250)
                    break
                case 250:
                    setSalesMin(251)
                    setSalesMax(500)
                    break
                case 500:
                    setSalesMin(501)
                    setSalesMax(5000)
                    break
                case 5000:
                    setSalesMin(5001)
                    setSalesMax(50000)
                    break
                case 50000:
                    setSalesMin(50001)
                    setSalesMax(500000)
                    break
            }
        }
    }

    return (
        item.status !== 'closed' ?
        <Card
            boxShadow={'3px 2px 2px 1px rgba(0, 0, 0, 0.1)'}
            width={'100%'}
            paddingX={'1rem'}
            paddingY={'0.5rem'}
            rounded={'5px'}
            justifyContent={'space-between'}
            borderLeftStyle={'outset'}
            borderLeftWidth={'3px'}
            borderColor={referencePrice > item.price ? 'red' : 'green'}>
            <CardBody width={'100%'} display='flex' dir='row' alignItems={'center'} justifyContent={'space-between'}>

                <Box sx={{ display: 'flex', w: '75%', flexDirection: 'column' }}>
                {String(item.listing_type_id) === 'gold_pro' &&
                        <Text align="left" fontSize={'xs'} textColor='#00a650'>Ofrece cuotas s/interés</Text>
                        }
                <Link sx={{ p: 0, m: 0 }} href={item.permalink} isExternal>
                    <Text noOfLines={[1, 2]} mr='30px' sx={{ flex: 0.9 }} align="left" fontSize={'smaller'} fontWeight={'semibold'}>{item.title}</Text>
                    </Link>
                        <Link href={seller?.permalink} isExternal>
                                {myName !== seller?.nickname ?
                            <Text fontSize={'2xs'}>{seller?.nickname}</Text>
                            :
                            <Text fontSize={'2xs'}>PUBLICACIÓN PROPIA</Text>
                                    }
                        </Link>
                    
                </Box>
                {item.shipping.logistic_type === 'fulfillment' && <FullfilmentIcon/> }
            <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                <Box flexDir={'column'} display='flex' justifyContent={'center'}>
                    
                    {item.original_price && <Text decoration={'line-through'} align="right" fontSize={'2xs'}>${item.original_price}</Text>}
                    <Text align="right" fontSize={'smaller'} fontWeight={'semibold'}>${item.price}</Text>
                </Box>
                <Box flexDir={'column'} display='flex' justifyContent={'left'} px={3} w={'100px'}>
                    <Tooltip fontSize='2xs' label='Stock'><Text fontSize={'2xs'} display={'flex'} flexDir='row' alignItems={'center'}><Box mr='5px'><BiBox/></Box> {stockMin}/{stockMax}</Text></Tooltip>
                        <Tooltip fontSize='2xs' label='Ventas'><Text fontSize={'2xs'} display={'flex'} flexDir='row' alignItems={'center'}><Box mr='5px'><BiCart /></Box> {salesMin === salesMax ? salesMax : `${salesMin}/${salesMax}`}</Text></Tooltip>
                        
                            
                        
                </Box>    
                <Stack minWidth={'70px'} direction={'column'} alignItems={'center'} spacing={0} display={'flex'} flexDir='row'>     
                    <Box>
                        <IconButton onClick={()=>removeItem(item.id)} variant="ghost" color="primary" size={'xs'} aria-label="Información" icon={<DeleteIcon fontSize={'2xs'}/>}/>
                    </Box>
                    {item.price < referencePrice && <Box>
                        <Tooltip
                            label={`El precio es menor al sugerido por el proveedor (${100-Math.ceil(item.price*100/referencePrice)}% más barato).`}
                            fontSize='2xs'
                            hasArrow
                            arrowSize={8}
                            placement='start'
                            width={180}
                        >
                            <WarningTwoIcon fontSize='2xs' cursor={'pointer'}/>
                        </Tooltip>
                    </Box>}
                </Stack>
            </Box>
        </CardBody>
    </Card> : <Card
            boxShadow={'3px 2px 2px 1px rgba(0, 0, 0, 0.1)'}
            width={'100%'}
            paddingX={'1rem'}
            paddingY={'0.5rem'}
            rounded={'5px'}
            justifyContent={'space-between'}
            borderLeftStyle={'outset'}
            borderLeftWidth={'3px'}
            borderColor={'grey'}>
            <CardBody width={'100%'} display='flex' dir='row' alignItems={'center'} justifyContent={'space-between'}>

                <Box sx={{ display: 'flex', w: '75%', flexDirection: 'column' }}>
                {String(item.listing_type_id) === 'gold_pro' &&
                        <Text align="left" fontSize={'xs'} textColor='#00a650'>Ofrece cuotas s/interés</Text>
                        }
                <Link sx={{ p: 0, m: 0 }} href={item.permalink} isExternal>
                    <Text noOfLines={[1, 2]} mr='30px' sx={{ flex: 0.9 }} align="left" fontSize={'smaller'} fontWeight={'semibold'}>{item.title}</Text>
                    </Link>
                        <Link href={seller?.permalink} isExternal>
                                {myName !== seller?.nickname ?
                            <Text fontSize={'2xs'}>{seller?.nickname}</Text>
                            :
                            <Text fontSize={'2xs'}>PUBLICACIÓN PROPIA</Text>
                                    }
                        </Link>
                    
                </Box>
                
                    <Text align="right" fontSize={'smaller'} fontWeight={'semibold'}>Publicación finalizada</Text>
                <Box flexDir={'column'} display='flex' justifyContent={'left'} px={3} w={'100px'}>  
                </Box>    
                <Stack minWidth={'70px'} direction={'column'} alignItems={'center'} spacing={0} display={'flex'} flexDir='row'>     
                    <Box>
                        <IconButton onClick={()=>removeItem(item.id)} variant="ghost" color="primary" size={'xs'} aria-label="Información" icon={<DeleteIcon fontSize={'2xs'}/>}/>
                    </Box>
                    {item.price < referencePrice && <Box>
                        <Tooltip
                            label={`El precio es menor al sugerido por el proveedor (${100-Math.ceil(item.price*100/referencePrice)}% más barato).`}
                            fontSize='2xs'
                            hasArrow
                            arrowSize={8}
                            placement='start'
                            width={180}
                        >
                            <WarningTwoIcon fontSize='2xs' cursor={'pointer'}/>
                        </Tooltip>
                    </Box>}
                </Stack>
        </CardBody>
    </Card>)
}

export default CompetitorRow