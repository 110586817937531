import { HamburgerIcon } from "@chakra-ui/icons"
import { Box, Divider, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import React from "react"
import CoexistenceButton from "./CoexistenceButton"
import DonateButton from "./DonateButton"
import LogoutButton from "./LogoutButton"
import PlansButton from "./PlansButton"
import SellerButton from "./SellerButton"
import ThemeButton from "./ThemeButton"

const MenuButton = () => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)
    
 
  
  
    return (
      <>
        <IconButton onClick={onOpen} colorScheme='blue' aria-label='Menu' icon={<HamburgerIcon/>} />
  
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Box display={'flex'} flexDir={'column'} m='30px'>
                <PlansButton/>
                <CoexistenceButton />
                <DonateButton />
                <SellerButton />
                <ThemeButton />
                <Divider my='1rem' />
                <LogoutButton />
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    )
  }

  export default MenuButton