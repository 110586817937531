
import { Button, useColorMode } from "@chakra-ui/react"

const ThemeButton = () => {
    const { colorMode, toggleColorMode } = useColorMode()
    return (
        <Button colorScheme={'purple'} margin='0.5rem' fontSize={'smaller'} onClick={toggleColorMode}>
          Modo {colorMode === 'light' ? 'oscuro' : 'claro'}
        </Button>
)}

export default ThemeButton