import { BrowserRouter, Route, Routes } from "react-router-dom"
import HomePage from "./pages/Home"
import PlansPage from "./pages/Plans"
import DonatePage from "./pages/Donate"
import LoginPage from "./pages/Login"
import CoexistencePage from "./pages/Coexistence"
import SetSellerPage from "./pages/SetSeller"

import AuthRoute from "./components/AuthRoute"

export interface IapplicationProps {}

const App: React.FunctionComponent<IapplicationProps> = (props) => {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <AuthRoute>
              <HomePage />
            </AuthRoute>
          }
        />
        <Route
          path="/login"
          element={
            <AuthRoute>
              <LoginPage />
            </AuthRoute>
          }
        />
        <Route
          path="/convivencia"
          element={
            <AuthRoute>
              <CoexistencePage />
            </AuthRoute>
          }
        />
        <Route
          path="/planes"
          element={
            <AuthRoute>
              <PlansPage />
            </AuthRoute>
          }
        />
        <Route
          path="/donar"
          element={
            <AuthRoute>
              <DonatePage />
            </AuthRoute>
          }
        />
        <Route
          path="/vendedor"
          element={
            <AuthRoute>
              <SetSellerPage />
            </AuthRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default App