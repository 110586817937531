import { Box, Input, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Spinner, Text } from "@chakra-ui/react"
import { SetStateAction, useEffect, useState } from "react"
import api from "../api"
import { Comparison, queryItem } from "../utils/Interfaces"
import { itemsPerPage, maxLenght } from "../utils/config"
import ItemCard from "./ItemCard"

type SearchDialogProps = {
  dataInput: Comparison
  addItemToComparison(item:queryItem):void
  removeItemToComparison(item: queryItem): void
  sellerID: string
  comparisonLength: number
}

function SearchDialog(props: SearchDialogProps) {

  const [searchCriteria, setSearchCriteria] = useState<string>("")
  const [inputValue, setInputValue] = useState<string>("")
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [isLoading, setIsLoading] = useState<Boolean>(false)
  const [isError, setIsError] = useState<Boolean>(false)
  const [articlesArray, setArticlesArray] = useState<queryItem[]>([])
  const [paginationCount, setPaginationCount] = useState<number>(0)
  const [comparisonListLength, setComparisonListLength] = useState<number>(props.comparisonLength)

  function addItemToComparison(item:queryItem) {
    props.addItemToComparison(item)
    setComparisonListLength(comparisonListLength+1)
  }
  function removeItemToComparison(item:queryItem) {
    props.removeItemToComparison(item)
    setComparisonListLength(comparisonListLength-1)
  }

  function searchArticle(criteria: string, page: number) {
    setIsLoading(true)
    setIsError(false)
    api.getArticles(criteria, page)
      .then((json) => {
        setArticlesArray(json.results)
        setIsLoading(false)
        setPaginationCount((Math.ceil(json.paging.total / itemsPerPage)))
      })
      .catch((err) => {
        console.log('Error:', err)
        setIsLoading(false)
        setIsError(true)
        setPaginationCount(0)
        setArticlesArray([])
      })
  }

  useEffect(() => {
    if (searchCriteria) {
      searchArticle(searchCriteria, pageNumber)
    } else {
      setArticlesArray([])
      setPaginationCount(0)
      setIsLoading(false)
    }
        
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, searchCriteria]);

    const handlePageChange = (value: any) => {
      setPageNumber(value as number);
    };


    const onChangeInput = (event: { target: { value: SetStateAction<string> } }) => {
      if (event.target.value !== searchCriteria) {
        setInputValue(event.target.value)
      }
    }
  
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', margin: '0', padding: '0', flex: 1, maxHeight: '90vh', alignItems: 'center' }}>
        <Text>Buscar artículos</Text>
        <Input
          sx={{
            margin: '10px',
            width: '80%',
            height: '30px',
            minHeight: '30px'
          }}

          onKeyDown={(e: { key: string; preventDefault: () => void }) => {
            if (e.key === 'Enter') {
              console.log('1')
              console.log(inputValue)

              if (inputValue !== searchCriteria) {
                console.log('Debería setear searchCriteria y Página')
                console.log(`searchCriteria:${searchCriteria}`)
                console.log(`Página:${pageNumber}`)

                setPageNumber(1)
                setSearchCriteria(inputValue)
              }
              e.preventDefault();
            }
          }}
          placeholder={'Buscar...'}
          onChange={onChangeInput}
        />
        <Box sx={{ margin: '0.5rem', flex: 1, overflowY: 'scroll', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {isLoading ?
            <Box
              sx={{
                flex: '1',
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <Text>Obteniendo artículos...</Text>
              <Spinner />
            </Box> :
            articlesArray.map((item, i) =>
              <ItemCard
                key={i}
                item={item}
                checked={
                  props.dataInput.comparisonItemsArray.some(elem => {
                    return JSON.stringify({ id: item.id, seller: item.seller.permalink.substring(item.seller.permalink.indexOf('ar/') + 3, item.seller.permalink.length) }) === JSON.stringify(elem);
                  })
                }
                addItem={() => addItemToComparison(item)}
                removeItem={() => removeItemToComparison(item)}
                checkeable={
                  comparisonListLength >= maxLenght &&
                  !(props.dataInput.comparisonItemsArray.some(elem => {
                  return JSON.stringify({ id: item.id, seller: item.seller.permalink.substring(item.seller.permalink.indexOf('ar/') + 3, item.seller.permalink.length) }) === JSON.stringify(elem);
                }))}
              />
            )
          }
          {isError && <Text>No se han encontrado resultados. Intente con otro criterio de búsqueda.</Text>}
        </Box>
        {
          paginationCount > 0 &&
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <NumberInput size='xs' sx={{ maxWidth: '100px' }} defaultValue={1} min={1} max={paginationCount} onChange={handlePageChange}>
              <NumberInputField readOnly />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Text sx={{ marginLeft: '0.75rem' }}>de {paginationCount} </Text>
          </Box>
        }
      
      </Box>
    )
  }
  
export default SearchDialog
