import { useEffect, useState } from "react"
import api from "../api"
import ItemCard from "./ItemCard"
import { queryItem, storeStock } from "../utils/Interfaces"
import { Spinner, Text, Box, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from "@chakra-ui/react"
import { itemsPerPage } from "../utils/config"
import StockCard from "./StockCard"

type SearchDialogProps = {
  dataInput:storeStock[]
  addItemToComparison(item:queryItem):void
  removeItemToComparison(item: queryItem): void
  sellerID: string
}

function CoexistenceDialog(props: SearchDialogProps) {

  const [inputValue, setInputValue] = useState<string>("")
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [isLoading, setIsLoading] = useState<Boolean>(false)
  const [isError, setIsError] = useState<Boolean>(false)
  const [articlesArray, setArticlesArray] = useState<queryItem[]>([])
  const [paginationCount, setPaginationCount] = useState<number>(0)


  function searchArticle(ID: string, page: number) {
    setIsLoading(true)
    setIsError(false)
    api.getAllMyCoexistenceArticles(page, ID)
      .then((json) => {
        setArticlesArray(json.results)
        setIsLoading(false)
        setPaginationCount((Math.ceil(json.paging.total / itemsPerPage)))
      })
      .catch((err) => {
        console.log('Error:', err)
        setIsLoading(false)
        setIsError(true)
        setPaginationCount(0)
        setArticlesArray([])
      })
  }

    useEffect(() => {
      searchArticle(props.sellerID, pageNumber)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, props.sellerID]);

    const handlePageChange = (value: any) => {
      setPageNumber(value as number);
    };


  
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', margin: '0', padding: '0', flex: 1, maxHeight: '90vh', alignItems: 'center' }}>
        <Text>Artículos con convivencia Full - Flex</Text>
        <Box sx={{ margin: '0.5rem', flex: 1, overflowY: 'scroll', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {isLoading ?
            <Box
              sx={{
                flex: '1',
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <Text>Obteniendo artículos...</Text>
              <Spinner />
            </Box> :
            articlesArray.map((item, i) =>
              <StockCard
                key={i}
                item={item}
                checked={props.dataInput.some(elem => { return item.id === elem.item.id })}
                addItem={() => props.addItemToComparison(item)}
                removeItem={() => props.removeItemToComparison(item)}
              />
            )
          }
          {isError && <Text>No se han encontrado resultados. Intente con otro criterio de búsqueda.</Text>}
        </Box>
        {
          paginationCount > 0 &&
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <NumberInput size='xs' sx={{ maxWidth: '100px' }} defaultValue={1} min={1} max={paginationCount} onChange={handlePageChange}>
              <NumberInputField readOnly />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Text sx={{ marginLeft: '0.75rem' }}>de {paginationCount} </Text>
          </Box>
        }
      
      </Box>
    )
  }
  
export default CoexistenceDialog
