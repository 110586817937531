import { Box, Button, ChakraProvider, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, FormControl, FormLabel, Input, Text, theme, useDisclosure, useToast } from "@chakra-ui/react"
import { getAuth, reload, signOut } from "firebase/auth"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getAllMyArticles } from "../api/meliapi"
import CoexistenceDialog from "../components/CoexistenceDialog"
import StockRow from "../components/StockRow"
import { queryItem, storeStock } from "../utils/Interfaces"
import { app } from "../utils/firebase"
import { getMyStock, setMySellerIDOnDB, setMyStockOnDB } from "../utils/utils"



function Coexistence() {
  const navigate = useNavigate()
  const auth = getAuth(app)
  const logout = () => {
    signOut(auth)
    localStorage.removeItem("uid")
    localStorage.removeItem("sellerID")
    localStorage.removeItem("profileUrl")
  }

  const toast = useToast()
  const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure()


  const [myStock, setMyStock] = useState<storeStock[]>([])
  const [mySellerID, setMySellerID] = useState<string>(localStorage.getItem("sellerID") || "")
  const [inputValue, setInputValue] = useState<string>('')
  const [collectionName, setCollectionName] = useState<string>(localStorage.getItem("uid") || "")
  const [reload, setReload] = useState<boolean>(false)

 
  function setSeller() {
    getAllMyArticles('', 1, inputValue).catch()
      .then(json => {
        if (json.results.length) {
          setMySellerID(inputValue)
          setMySellerIDOnDB(collectionName, inputValue)
        }
      })
      .catch(() => {
        toast({
        description: "Seller ID incorrecto o sin publicaciones.",
        status: 'error',
        duration: 2000,
        isClosable: true,
      })})
  }

  function backToHome() {
    navigate('/')
  }

  function addItemToStock(item: queryItem) {
    setMyStock([...myStock, { item: item, quantity: 0 }])
  }
  
  function removeItemFromStock(item: queryItem) {
    setMyStock(myStock.filter(row => row.item.id !== item.id)) 
  }
  
  function deleteStockRow(id:string) {
    setMyStock(myStock.filter(row => row.item.id !== id))
  }
  
  function updateStock(value:number, item: queryItem) {
    const index: number = myStock.findIndex(element => element.item.id === item.id)
    myStock[index].quantity = +value
    setReload(true)

  }

  useEffect(() => {
    setMyStockOnDB(collectionName, myStock)
    setReload(false)
  }, [myStock, reload]);

  

  if (mySellerID.length > 0) {
    return (
      <ChakraProvider theme={theme}>
        <Drawer
            isOpen={isOpenDrawer}
            placement='right'
            onClose={onCloseDrawer}
            size={'xl'}
            >
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerBody>
                  <CoexistenceDialog
                    dataInput={myStock}
                    addItemToComparison={addItemToStock}
                    removeItemToComparison={removeItemFromStock}
                    sellerID={mySellerID}
                  />
                </DrawerBody>
              </DrawerContent>
        </Drawer>
        <Box maxHeight='100vh' overflow='hide' sx={{ display: 'flex', flexDirection: 'row'}}>
          <Box padding='1rem'  sx={{ display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'top', width: '250px', backgroundColor: 'black' }}>
            <Button margin='0.5rem' colorScheme={'red'} onClick={logout} fontSize={'smaller'}>Cerrar Sesión</Button>
            <Button margin='0.5rem' colorScheme={'green'} onClick={()=>navigate("/")} fontSize={'smaller'}>Comparaciones</Button>
            <Divider marginY='1rem' />
          </Box>
          <Box maxHeight='100vh' overflow='hide' sx={{ display: 'flex', flexDirection: 'column', width:'100%', padding:'16px' }}>
            <Box overflow='hide' sx={{ display: 'flex', flexDirection: 'row', justifyContent:'space-around', width:'100%'}}>
              <Button marginBottom='0.5rem' onClick={onOpenDrawer} colorScheme='blue' fontSize={'smaller'}>
                Buscar Articulos
              </Button>
              <Button marginBottom='0.5rem' onClick={()=>console.log(myStock)} colorScheme='blue' fontSize={'smaller'}>
                Imprimir stock en consola
              </Button>
            </Box>
          {myStock.length > 0 ? myStock.map((item, key: number) => <StockRow item={item.item} quantity={0} removeItem={deleteStockRow} changeStock={updateStock} key={key} />) : <>No hay nada</>}
          </Box>
        </Box>
      </ChakraProvider>)
    
  } else {
    return (
      <ChakraProvider theme={theme}>
        <Box width={'100%'} height={'100vh'} display='flex'>
          <FormControl maxWidth={'350px'} margin='auto'>
            <FormLabel fontWeight={'hairline'}>Para ver tus publicaciones con "convivencia" debes ingresar tu seller ID.
              <br/>Si no eres vendedor vuelve a la página principal.</FormLabel>
            <Input isRequired placeholder='Seller ID' onChange={(e) => setInputValue(e.target.value)} />
            <Box marginY={'1rem'} display='flex'>
              <Button marginRight={'0.5rem'} flex={1} onClick={setSeller} colorScheme={'blue'}>Aceptar</Button>
              <Button marginLeft={'0.5rem'} flex={1} onClick={backToHome} colorScheme={'red'}>Volver</Button>
            </Box>
          </FormControl>
        </Box>
      </ChakraProvider>)
    }
}
  
export default Coexistence