import { Button } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

const DonateButton = () => {
  const navigate = useNavigate()
  function Donate() {
    navigate('/donar')
  }
  return (
    <Button onClick={Donate} margin='0.5rem' colorScheme='green' fontSize={'smaller'}>Donar</Button>
    )
  }
  export default DonateButton