import { Button } from "@chakra-ui/react"
import { getAuth, signOut } from "firebase/auth"
import { app } from "../utils/firebase"

const LogoutButton = () => {

    const auth = getAuth(app)
    const logout = () => {
      signOut(auth)
      localStorage.removeItem("uid")
      localStorage.removeItem("sellerID")
      localStorage.removeItem("profileUrl")
    }

    return (
        <Button margin='0.5rem' onClick={logout} colorScheme='red' fontSize={'smaller'}>Cerrar Sesión</Button>
    )
  }

  export default LogoutButton