import { CloseIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons"
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, ChakraProvider, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, FormControl, FormLabel, IconButton, Input, InputGroup, InputRightElement, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Stack, Text, theme, useDisclosure, useToast } from "@chakra-ui/react"
import { collection, onSnapshot, orderBy, query } from "firebase/firestore"
import { SetStateAction, useEffect, useState } from "react"
import { getAllMyArticles, getMyName, multiGetItems } from "../api/meliapi"
import CompetitorRow from "../components/CompetitorRow"
import MenuButton from "../components/MenuButton"
import ModalNewComparison from "../components/ModalNewComparison"
import SearchDialog from "../components/SearchDialog"
import { Comparison, CompetitorBody, queryItem } from "../utils/Interfaces"
import { maxLenght } from "../utils/config"
import { db } from "../utils/firebase"
import { AddComparisonToDB, deleteComparisonOfDB, setMySellerIDOnDB, updateComparisonDB } from "../utils/utils"


function Home() {

  
  const toast = useToast()
  const [comparison, setComparison] = useState<Comparison | undefined>(undefined)
  const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure()
  const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure()
  const [localArray, setLocalArray] = useState<any[]>([])
  const [tagsArray, setTagsArray] = useState<string[]>([])
  const [reload, setReload] = useState<Boolean>(false)
  const [mySellerID, setMySellerID] = useState<string>(localStorage.getItem("sellerID") || "")
  const [isSeller, setIsSeller] = useState<boolean>(true)
  const [inputValue, setInputValue] = useState<string>('')
  const [editingTitle, setEditingTitle] = useState<Boolean>(false)
  const [editingPrice, setEditingPrice] = useState<Boolean>(false)
  const [editingTag, setEditingTag] = useState<Boolean>(false)
  const [deletingComparison, setDeletingComparison] = useState<Boolean>(false)
  const [newValue, setNewValue] = useState<string>('')
  const [collectionName] = useState<string>(localStorage.getItem("uid") || "")
  const [myName, setMyName] = useState<string>('')
  const [competitorsArray, setCompetitorsArray] = useState<CompetitorBody[]>([])
  const [comparisonCriteria, setComparisonCriteria] = useState<string>('')

 
 
  useEffect(() => {
    if (collectionName !== "") {
      const collectionRef = collection(db, collectionName)
      const q = query(collectionRef, orderBy("displayName", "asc"))
      const unsub = onSnapshot(q, (snapshot) => setLocalArray(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))))
      return unsub
    }
  }, [collectionName])

  useEffect(() => {
    if (collectionName !== "") {
      setTagsArray(Array.from(new Set(localArray.map(item => (item.comparisonTag)))).filter(item => typeof item === 'string'))
    }
  }, [localArray])

 
  
  useEffect(() => {
    const settingName = async () => {
      setMyName(await getMyName(mySellerID))
    }
    if (mySellerID.length > 0) {
      settingName()
    }
  }, [mySellerID])

  useEffect(() => {
    const setCompetitors = async () => {
      if (comparison) {
        setCompetitorsArray(await multiGetItems(comparison.comparisonItemsArray))
      }
    }
    if (comparison) {
      if (comparison.comparisonItemsArray.length > 0) {
        setCompetitors()
      } else {
        setCompetitorsArray([])
      }
    }
  }, [reload, comparison])



  
  useEffect(() => {
    if (editingTitle || editingPrice || editingTag) {
      onOpenModal()
      setNewValue('')
    }
  }, [editingTitle, editingPrice, editingTag, onOpenModal])
  
  useEffect(() => {
    if (reload){
      if (comparison) {
        updateComparisonDB(
          comparison.displayName,
          comparison.referencePrice,
          comparison.comparisonItemsArray,
          comparison.comparisonTag,
          collectionName,
          comparison.id,
        )
        setReload(false)
      }
    }
  },[reload, comparison, collectionName])
    
  
  function addItemToComparisonApp(item: queryItem) {
    if (comparison) {
      if (comparison.comparisonItemsArray.length === maxLenght) {
        toast({
          description: `No puede incluir más de ${maxLenght} artículos en la misma comparación.`,
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      } else {
        comparison.comparisonItemsArray.push(
          {
            id: item.id,
            seller:
              item.seller.permalink.substring(
              item.seller.permalink.indexOf('ar/') + 3,
              item.seller.permalink.length
            )
          })
        setReload(true)
      }
    }
  }

  function removeItemToComparisonApp(item: queryItem) {
    if (comparison) {
      comparison.comparisonItemsArray = comparison.comparisonItemsArray.filter(
        competitor => competitor.id !== item.id)
      setReload(true)
    }  
  }
  
  function deleteCompetitorRow(id:string) {
    if (comparison) {
      comparison.comparisonItemsArray = comparison.comparisonItemsArray.filter(
        competitor => competitor.id !== id)
      setReload(true)
    }
  }
  
  const addComparison = async(name: string, referencePrice: number, comparisonTag:string) => {
    AddComparisonToDB(name, referencePrice, [], localStorage.getItem('uid') || "", comparisonTag)
    console.log(name, referencePrice, comparisonTag)
    setReload(true)
    setComparison(undefined)
    clearPayload()
  }

  function handleClickDelete() {
    if (comparison) {
      onOpenModal()
      setDeletingComparison(true)
    }
  }

  function clearPayload() {
    
  }

  function setSeller(){
    getAllMyArticles('', 1, inputValue).catch()
      .then(json => {
          setMySellerID(inputValue)
          setMySellerIDOnDB(collectionName, inputValue)
      })
      .catch(() => {
        toast({
        description: "Seller ID incorrecto o sin publicaciones.",
        status: 'error',
        duration: 2000,
        isClosable: true,
      })})
  }

  function editData() {
    if (editingPrice) {
      if (Number(newValue) && Number(newValue) > 0 && comparison) {
        comparison.referencePrice = Number(newValue)
        setReload(true)
        toast({
          description: "Precio de referencia actualizado con éxito.",
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
        closeModal()
      } else {
        toast({
          description: "Percio de referencia incorrecto",
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      }
    }

    if (editingTitle) {
      if (newValue.length > 0 && comparison) {
        comparison.displayName = newValue
        setReload(true)
        toast({
          description: "Título actualizado con éxito.",
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
        setEditingTitle(false)
        closeModal()
      } else {
        toast({
          description: "Título incorrecto",
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      }
    }

    if (editingTag) {
      if (newValue.length > 0 && comparison) {
        comparison.comparisonTag = newValue
        setReload(true)
        toast({
          description: "Categoría actualizada con éxito.",
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
        setEditingTag(false)
        closeModal()
      } else {
        toast({
          description: "No se puede asignar una categoría vacía.",
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      }
    }

    if (deletingComparison) {
      if (newValue === 'BORRAR' && comparison) {
        deleteComparisonOfDB(comparison.id, collectionName)
        setComparison(undefined)
        setReload(true)
        toast({
          description: "Se borró la comparación de la base de datos.",
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
        closeModal()
      } else {
        toast({
          description: "Debes escribir BORRAR en el cuadro de texto para borrar la comparación.",
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      }
    }
  }
    

  function closeModal() {
    setDeletingComparison(false)
    setEditingPrice(false)
    setEditingTitle(false)
    setNewValue('')
    onCloseModal()
  }

  const onChangeInput = (event: { target: { value: SetStateAction<string> } }) => {
    if (event.target.value !== comparisonCriteria) {
      setInputValue(event.target.value)
    }
  }

  function clearFilter() {
    setComparisonCriteria('')
    setInputValue('')
  }

  if (mySellerID.length>0 || !isSeller) {
        return (
      <ChakraProvider theme={theme}>
        <Modal
          isOpen={isOpenModal}
          onClose={closeModal}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody mt={10}>
              <Text>{deletingComparison ? 'Escribe BORRAR en el cuadro de texto para eliminar permanentemente la comparación. Esta acción es irreversible.' : 'Ingresa un nuevo valor en el cuadro de texto.' }</Text>
              <FormControl>
                <Input marginTop='1rem' onChange={(e) => setNewValue(e.target.value)} />
              </FormControl>
            </ModalBody>
  
            <ModalFooter>
              {((newValue.length>0 && !deletingComparison) || (newValue === 'BORRAR' && deletingComparison)) &&
                <Button colorScheme={deletingComparison ? 'red' : 'blue'} mr={3} onClick={editData}>
                  {deletingComparison ? 'Borrar' : 'Actualizar' }
                </Button>}
              <Button onClick={onCloseModal}>Cancelar</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {comparison &&
          <Drawer
            isOpen={isOpenDrawer}
            placement='right'
            onClose={onCloseDrawer}
            size={'xl'}
            >
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerBody>
                  <SearchDialog
                    dataInput={comparison}
                    addItemToComparison={addItemToComparisonApp}
                    removeItemToComparison={removeItemToComparisonApp}
                    sellerID={mySellerID}
                    comparisonLength={comparison.comparisonItemsArray.length}
                  />
                </DrawerBody>
              </DrawerContent>
          </Drawer> }
        
        <Box maxHeight={'100vh'} height={'100vh'} display={'flex'} flexDir={'column'}>
          <Box display={'flex'} flexDir={'row'} flex={1} maxH='96%'>
            <Box padding='1rem' display='flex' flexDirection='column' justifyContent='top' w='250px' maxW='250px' minW={'250px'} backgroundColor='gray.900' flex={1}>
              
              <Stack direction={'row'} justifyContent='center'>
                <ModalNewComparison newComparison={addComparison}/>
                <IconButton disabled={comparison?false:true} onClick={handleClickDelete} colorScheme='red' aria-label='Borrar' icon={<DeleteIcon/>} />
                <MenuButton />
              </Stack>

              <Divider marginY='1rem' />

              {localArray.length <= 0 ? <Text fontWeight='200' color='grey'>No hay ninguna comparación, creala clickeando el botón con el símbolo +.</Text> :
              <InputGroup my={'0.5rem'} size='xs'>
                <Input
                  value={inputValue}
                  pr='4.5rem'
                  onKeyDown={(e: { key: string; preventDefault: () => void }) => {
                    if (e.key === 'Enter') {
                      if (inputValue !== comparisonCriteria) {setComparisonCriteria(inputValue)}
                      e.preventDefault();
                    }
                  }}
                  placeholder={'Buscar...'}
                  onChange={onChangeInput}
                />
                <InputRightElement width='2rem'>
                  {inputValue.length > 0 && <IconButton h='1.75rem' variant='ghost' size={'xs'} onClick={clearFilter} icon={<CloseIcon />} aria-label={"delete-filtering"} />}
                </InputRightElement>
              </InputGroup>
              }
              <Box
                display={'flex'}
                marginY='1rem'
                overflowY='auto'
                css={{
                  '&::-webkit-scrollbar': {
                    width: '2px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'white',
                    borderRadius: '12px',
                  },
                    }}
                    flex={.25}
              >
                <Stack direction="column" spacing={2}>
                  { comparisonCriteria.length > 0 && localArray.filter((item:Comparison)=> item.displayName.toLowerCase().includes(comparisonCriteria.toLowerCase())).map((item, i) => (
                      <Box key={i} onClick={()=>setComparison(item)} >
                      <Text fontSize={'xs'} color={comparison === item ? 'cyan.500' : 'whiteAlpha.700'} fontWeight={comparison === item ? 'semibold' : 'hairline'} marginX={'0.5rem'} cursor={'pointer'}>
                          {item.displayName}
                        </Text>
                      </Box>))}
                </Stack>
                      
              </Box>
              <Divider marginY='1rem' />
              <Text color={'white'} fontWeight='hairline' fontSize={'xs'} >Mis comparaciones:</Text>        
              <Box
                display={'flex'}
                marginY='1rem'
                overflowY='auto'
                css={{
                  '&::-webkit-scrollbar': {
                    width: '2px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'white',
                    borderRadius: '12px',
                  },
                    }}
                    flex={1}
                  >
                    <Accordion allowToggle flex={1}>
                      <AccordionItem>
                        <h2>
                          <AccordionButton _expanded={{color: 'green.500', fontWeight: 'semibold' }} fontSize={'xs'}>
                            <Box as="span" flex='1' textAlign='left'>
                              Sin Categoría
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          <Stack direction="column" spacing={2}>
                            {localArray.filter((item)=> item.comparisonTag === undefined || '').map((item, i) => (
                                <Box key={i} onClick={()=>setComparison(item)} >
                                <Text fontSize={'xs'} color={comparison === item ? 'cyan.500' : 'whiteAlpha.700'} fontWeight={comparison === item ? 'semibold' : 'hairline'} marginX={'0.5rem'} cursor={'pointer'}>
                                    {item.displayName}
                                  </Text>
                                </Box>
                              ))}
                          </Stack>
                        </AccordionPanel>
                      </AccordionItem>
                      { tagsArray.map((itemTag, index) => (
                          <AccordionItem  key={index}>
                          <h2>
                            <AccordionButton _expanded={{color: 'green.500', fontWeight: 'semibold' }} fontSize={'xs'}>
                              <Box as="span" flex='1' textAlign='left'>
                                {itemTag}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                          <Stack direction="column" spacing={2}>
                            {localArray.filter((item:Comparison)=> item.comparisonTag === itemTag).map((item, i) => (
                                <Box key={i} onClick={()=>setComparison(item)} >
                                <Text fontSize={'xs'} color={comparison === item ? 'cyan.500' : 'whiteAlpha.700'} fontWeight={comparison === item ? 'semibold' : 'hairline'} marginX={'0.5rem'} cursor={'pointer'}>
                                    {item.displayName}
                                  </Text>
                                </Box>
                              ))}
                          </Stack>
                        </AccordionPanel>
                          </AccordionItem>
                        ))}
                    </Accordion>
              </Box>    
            </Box>
            <Box padding='1rem' maxH='100%' display='flex' flexDirection='column' justifyContent='space-between' width='auto' backgroundColor='smoke-white' flex={1} overflowY='clip' >
              {comparison ?
              <Box marginX='2rem'display='flex' flexDir={'column'} flex={1} maxH={'100%'}>
                <Box  display='flex' flexDir={'row'} justifyContent={'space-between'} sx={{ alignItems: 'center' }}>
                  <Box>
                    <Box display='flex' flexDir='row' alignItems='center'>
                      <Text fontSize='xl' fontWeight={600}>{comparison.displayName} ({comparison.comparisonItemsArray.length}/{maxLenght})</Text>
                      <IconButton
                        variant='ghost'
                        colorScheme='blue'
                        aria-label='Edit Comparison'
                        size='md'
                        icon={<EditIcon boxSize='3' />}
                        onClick={()=>setEditingTitle(true)}
                      />
                    </Box>
                    <Box display='flex' flexDir='row' alignItems='center'>
                      <Text fontSize='md'>Precio mínimo de venta: ${comparison.referencePrice}</Text>
                      <IconButton
                          variant='ghost'
                          colorScheme='blue'
                          aria-label='Edit Comparison'
                          size='md'
                          icon={<EditIcon boxSize='3' />}
                          onClick={()=>setEditingPrice(true)}
                      />
                    </Box>
                        <Box display='flex' flexDir='row' alignItems='center'>
                          <Text fontSize='md'>[ {comparison.comparisonTag ? comparison.comparisonTag : 'Sin categoría'} ]</Text>
                          <IconButton
                              variant='ghost'
                              colorScheme='blue'
                              aria-label='Edit Comparison'
                              size='md'
                              icon={<EditIcon boxSize='3' />}
                              onClick={()=>setEditingTag(true)}
                            />
                      </Box>
                  </Box>
                  <Box display='flex' flexDir='column'>
                    <Button marginBottom='0.5rem' onClick={onOpenDrawer} colorScheme='blue' fontSize={'smaller'}>
                    Buscar Articulos
                    </Button>
                    <Button marginTop='0.5rem' disabled={true} colorScheme='green' fontSize={'smaller'}>
                    Exportar informe
                    </Button>
                  </Box>
                </Box>  
                <Box
                    flex={1}
                    w={'80%'}
                    maxW='1000px'
                    m='auto'
                    marginY='1rem'
                    overflowY={'auto'}
                    css={{
                      '&::-webkit-scrollbar': {
                        width: '2px',
                      },
                      '&::-webkit-scrollbar-track': {
                        width: '4px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: 'white',
                        borderRadius: '12px',
                      },
                    }}
                  >
                    <Stack direction="column" spacing={2} >
                      {(competitorsArray.length>0 && comparison) ? (competitorsArray.sort((a,b) => a.price - b.price).map((item:CompetitorBody, i: number) =>
                      <CompetitorRow
                        key={i}
                        item={item}
                        referencePrice={comparison.referencePrice}
                        myName={myName}
                        removeItem={deleteCompetitorRow}
                        />)):
                        <Text>Aquí aparecerán los competidores una vez agregados.</Text>
                      }
                    </Stack>
                </Box>
                </Box>
              : <Text>Elegir comparación para visualizarla.</Text>}
            </Box> 
          </Box>
          <Box backgroundColor={'gray.700'} maxH={'4%'} minH={'4%'} h={'4%'} display='flex' flexDir={'row'} justifyContent='center' alignItems={'center'}>
            <Text fontFamily={'Dosis, sans-serif'} my='auto' fontSize={'xs'} textAlign='center' flexDir='row' display='flex' justifyContent={'center'}>
              Aplicación creada por&nbsp; 
              <Link href={'http://sdl.ar'} target={'_blank'} display={'flex'} flexDir='row'>
                <Text fontWeight={'light'}>S</Text>
                <Text fontWeight={'bold'}>DL</Text>.
              </Link>
            </Text>
          </Box>
        </Box>
      </ChakraProvider>)    
  } else {
    return (
      <ChakraProvider theme={theme}>
        <Box width={'100%'} height={'100vh'} display='flex'>
          <FormControl maxWidth={'350px'} margin='auto'>
            <FormLabel fontWeight={'hairline'}>Si eres vendedor ingresa tu seller ID para continuar:</FormLabel>
            <Input isRequired placeholder='Seller ID' onChange={(e) => setInputValue(e.target.value)} />
            <Box marginY={'1rem'} display='flex'>
              <Button marginRight={'0.5rem'} flex={1} onClick={setSeller} colorScheme={'blue'}>Aceptar</Button>
              <Button marginLeft={'0.5rem'} flex={1} onClick={()=>setIsSeller(false)} colorScheme={'red'}>No soy vendedor</Button>
            </Box>
          </FormControl>
        </Box>
      </ChakraProvider>)
  }
}

export default Home
