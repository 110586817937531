import { AddIcon } from "@chakra-ui/icons"
import { useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Input, ModalFooter, useToast, IconButton } from "@chakra-ui/react"
import React, { useState } from "react"

interface Props {
  newComparison(name:string, referencePrice:number, comparisonTag:string) : void
}
const ModalNewComparison = ({newComparison}:Props) => {
    const [comparisonTitle, setComparisonTitle] = useState<string>('')
    const [comparisonTag, setComparisonTag] = useState<string>('')
    const [referencePrice, setReferencePrice] = useState<string>()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)
    const toast = useToast()
  
  
    function clearPayload() {
      setComparisonTag('')
      setReferencePrice('')
      setComparisonTitle('')
    }
    
  function handleClickAdd() {
    if (comparisonTitle.length>0 && Number(referencePrice)>=0) {
      newComparison(comparisonTitle, Number(referencePrice), comparisonTag)
      onClose()
      clearPayload()
      toast({
        title: 'Comparación agregada',
        description: "Se ha agregado una nueva comparación con éxito.",
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
    } else if (comparisonTitle.length<=0) {
      toast({
      title: 'El título es demasiado corto.',
      status: 'error',
      duration: 2000,
      isClosable: true,
    })
    } else {
      toast({
        title: 'El precio de referencia debe ser mayor o igual a 0.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
      
    }
  }
  
    return (
      <>
              <IconButton onClick={onOpen} colorScheme='green' aria-label='Borrar' icon={<AddIcon/>} />
  
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Crear nueva comparación</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>Título</FormLabel>
                <Input isRequired ref={initialRef} placeholder='Título de la comparación' onChange={(e) => setComparisonTitle(e.target.value)} />
              </FormControl>
  
              <FormControl mt={4}>
                <FormLabel>Precio de venta al público</FormLabel>
                <Input isRequired placeholder='Precio sugerido por el proveedor' onChange={(e) => setReferencePrice(e.target.value)}/>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Etiqueta</FormLabel>
                <Input isRequired placeholder='Etiqueta para agrupar comparaciones' onChange={(e) => setComparisonTag(e.target.value)} />
              </FormControl>
            </ModalBody>

            
  
            <ModalFooter>
              {(comparisonTitle && referencePrice) &&
                <Button type='submit' colorScheme='blue' mr={3} onClick={handleClickAdd}>
                  Agregar
                </Button>}
              <Button onClick={onClose}>Cancelar</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

  export default ModalNewComparison

