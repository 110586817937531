import { Button } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

const PlansButton = () => {
  const navigate = useNavigate()
  
    return (
      <Button margin='0.5rem' colorScheme={'blue'} onClick={()=>navigate("/planes")} fontSize={'smaller'}>Planes</Button>
    )
  }

  export default PlansButton